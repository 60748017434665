<template>
  <div class="root">
    <div class="header">
      <h1>系统环境监控</h1>
    </div>
    <div class="content">
      <el-row>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">系统信息</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="1"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="操作系统">{{
                monitorInfo.os
              }}</el-descriptions-item>
              <el-descriptions-item label="JAVA版本">{{
                monitorInfo.jvmJavaVersion
              }}</el-descriptions-item>
              <el-descriptions-item label="程序启动时间">{{
                monitorInfo.runTime
              }}</el-descriptions-item>
            </el-descriptions>
          </div>
        </el-col>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">CPU</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="1"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="CPU信息">{{
                monitorInfo.cpuInfo
              }}</el-descriptions-item>
              <el-descriptions-item label="CPU使用率"
                >{{ monitorInfo.cpuUseRate }} (%)</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">内存</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="1"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="内存总量"
                >{{ monitorInfo.ramTotal }} (G)</el-descriptions-item
              >
              <el-descriptions-item label="已用内存"
                >{{ monitorInfo.ramUsed }} (G)</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </el-col>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">磁盘</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="1"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="磁盘总量"
                >{{ monitorInfo.diskTotal }} (G)</el-descriptions-item
              >
              <el-descriptions-item label="已用磁盘"
                >{{ monitorInfo.diskUsed }} (G)</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </el-col>
      </el-row>
      <el-row>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">JVM堆内存</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="2"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="初始大小"
                >{{ monitorInfo.jvmHeapInit }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="最大可用"
                >{{ monitorInfo.jvmHeapMax }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="已使用"
                >{{ monitorInfo.jvmHeapUsed }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="已申请"
                >{{ monitorInfo.jvmHeapCommitted }} (M)</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </el-col>
        <el-col :lg="12" :xs="24" class="box">
          <div class="title">JVM非堆内存</div>
          <div class="info">
            <el-descriptions
              title=""
              :column="2"
              :size="size"
              direction="horizontal"
            >
              <el-descriptions-item label="初始大小"
                >{{ monitorInfo.jvmNonHeapInit }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="最大可用"
                >{{ monitorInfo.jvmNonHeapMax }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="已使用"
                >{{ monitorInfo.jvmNonHeapUsed }} (M)</el-descriptions-item
              >
              <el-descriptions-item label="已申请"
                >{{ monitorInfo.jvmNonHeapCommitted }} (M)</el-descriptions-item
              >
            </el-descriptions>
          </div>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
  
  <script>
  export default {
    data() {
      return {
        monitorInfo: {
            os:'',//操作系统
            runTime:'',//程序启动时间
            jvmJavaVersion:'',//java版本
          
            jvmHeapInit:'',//jvm内存的初始大小
            jvmHeapMax:'',//jvm最大可用内存量
            jvmHeapUsed:'',//jvm已使用的内存量
            jvmHeapCommitted:'',//jvm已申请的内存量

            jvmNonHeapInit:'',//jvm内存的初始大小
            jvmNonHeapMax:'',//jvm最大可用内存量
            jvmNonHeapUsed:'',//jvm已使用的内存量
            jvmNonHeapCommitted:'',//jvm已申请的内存量

            cpuInfo:'',//CPU信息
            cpuUseRate:'',//CPU使用率
            ramTotal:'',//系统内存总量
            ramUsed:'',//已使用的系统内存量
            diskTotal:'',//系统磁盘总量
            diskUsed:''//已使用的系统磁盘量
        }
      };
    },
    created() {
      this.setupWebSocket();
    },
    computed:{
        rendelogHtml(){
            if (this.logs.length === 0) {
                return "";
            } else {
                return this.logs;
            }
        }
    },
    methods: {
      
      setupWebSocket() {

        const ws = new WebSocket('wss://wandao666.top/websocket/monitor?token=' + window.localStorage.getItem('token')); // 替换成你的WebSocket服务器地址
  
        ws.onopen = () => {
          console.log('WebSocket connected');
        };
  
        ws.onmessage = (event) => {
            if (event.data) {
               this.monitorInfo = JSON.parse(event.data);
               console.log(this.monitorInfo)
            }         
        };
  
        ws.onclose = () => {
          console.log('WebSocket closed');
        };
  
        ws.onerror = (error) => {
          console.error('WebSocket error:', error);
          window.location.href = '/';
        };
      },
     
     
    }
  };
  </script>
<style lang="less" scoped>
//style="width:100%;height: 500px;background-color: ghostwhite; overflow: auto;
.root {
  display: flex;
  justify-content: start;
  flex-direction: column;
  width: 100%;
  height: 100%;
  background-color: ghostwhite;
  .header {
    height: 60px;
  }
  .content {
    width: 100%;
    height: calc(100%-60px);
    .box {
      padding: 10px;
      width: 50%;
      //height: 180px;
      overflow: auto;
      text-align: left;
      .title {
        font-size: 16px;
        font-weight: 600;
        height: 30px;
      }
    }
  }
}
</style>