<template>
  <div class="assistant-container">
    <div class="sidebar">
      <div class="sidebar-header">
        <button class="new-chat" @click="createNewChat">+ 新对话</button>
      </div>
      <div class="chat-list">
        <div
          v-for="(chat, index) in chats"
          :key="chat.id"
          class="chat-item"
          :class="{ active: activeIndex === index }"
          @click="switchChat(index)"
        >
          {{ chat.title }}
        </div>
      </div>
    </div>
    <div class="main-content">
      <div class="message-area">
        <div
          v-for="(message, index) in activeChat.messages"
          :key="index"
          class="message"
          :class="{
            'user-message': message.role === 'user',
            'ai-message': message.role === 'assistant',
          }"
        >
          <div class="message-content">
            <div
              v-if="message.role === 'assistant'"
              v-html="renderMarkdown(message.content)"
            ></div>
            <div v-else>{{ message.content }}</div>
            <div v-if="message.anserFiles && message.anserFiles.length > 0">
              <img
                v-for="(fileUrl, fileIndex) in message.anserFiles"
                :key="fileIndex"
                :src="fileUrl"
                alt="Uploaded Image"
                style="max-width: 200px; max-height: 200px; margin-top: 10px"
              />
            </div>
          </div>
          <div class="message-time">
            {{ formatTime(message.timestamp) }}
          </div>
        </div>
        <div v-if="loading" class="loading-animation">
          <div class="loading-dot"></div>
          <div class="loading-dot"></div>
          <div class="loading-dot"></div>
        </div>
      </div>
      <div class="selected-images-container">
        <div v-if="selectedImages.length > 0" class="selected-images">
          <div
            v-for="(image, index) in selectedImages"
            :key="index"
            class="selected-image"
            @click.stop="showPreviewImage(image.url)"
          >
            <img :src="image.url" alt="Selected Image" />
            <button
              @click.stop="removeImage(index)"
              class="remove-image-button"
            >
              删除
            </button>
          </div>
        </div>
      </div>
      <div class="input-area">
        <input
          type="file"
          accept="image/*"
          @change="handleImageSelect"
          style="display: none"
          ref="imageInput"
        />
        <textarea
          v-model="inputMessage"
          @keydown.enter.exact.prevent="sendMessage"
          @keydown.shift.enter.prevent
          placeholder="输入你的消息(可让助手聊天、画画、做视频、分析图片内容)..."
          class="message-input"
          rows="3"
        ></textarea>
        <div class="button-container">
          <button @click="$refs.imageInput.click()" class="select-image-button">
            选择图片
          </button>
          <button
            @click="sendMessage"
            :disabled="isSending"
            class="send-button"
          >
            发送
          </button>
        </div>
      </div>
    </div>

    <!-- 图片预览模态框 -->
    <div
      v-if="previewImage"
      class="image-preview-modal"
      @click="previewImage = null"
    >
      <img :src="previewImage" alt="Preview Image" class="preview-image" />
    </div>
  </div>
</template>

<script>
import MarkdownIt from "markdown-it";
import axios from "axios";
const md = new MarkdownIt();

export default {
  data() {
    return {
      activeIndex: 0,
      inputMessage: "",
      chats: [
        {
          id: Date.now(),
          title: "对话 1",
          messages: [
            {
              role: "assistant",
              content: "你好！我是你的小助理，有什么可以帮您的？",
              timestamp: new Date()
            }
          ]
        }
      ],
      loading: false,
      selectedImages: [],
      previewImage: null, // 用于存储预览图片的 URL
	  isSending: false, // 新增状态变量
    };
  },
  computed: {
    activeChat() {
      return this.chats[this.activeIndex];
    }
  },
  methods: {
    createNewChat() {
      const newChat = {
        id: Date.now(),
        title: `新对话 ${this.chats.length + 1}`,
        messages: [
          {
            role: "assistant",
            content: "你好！有什么可以帮你的？",
            timestamp: new Date()
          }
        ]
      };
      this.chats.push(newChat);
      this.activeIndex = this.chats.length - 1;
    },
    switchChat(index) {
      this.activeIndex = index;
    },
    handleImageSelect(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              this.compressImage(image, file).then((compressedImage) => {
                this.uploadImage(compressedImage.file, compressedImage.dataURL);
              });
            };
          };
          reader.readAsDataURL(file);
        }
      }
    },
    async compressImage(image, file) {
      const maxSize = 1 * 1024 * 1024; // 1MB
      const maxWidth = 1024;
      let quality = 0.9;

      let canvas = document.createElement("canvas");
      let ctx = canvas.getContext("2d");

      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);

      let dataURL = canvas.toDataURL(file.type, quality);
      let blob = await this.dataURLtoBlob(dataURL);

      while (blob.size > maxSize && quality > 0.1) {
        quality -= 0.1;
        dataURL = canvas.toDataURL(file.type, quality);
        blob = await this.dataURLtoBlob(dataURL);
      }

      const compressedFile = new File([blob], file.name, { type: file.type });
      return {
        file: compressedFile,
        dataURL: dataURL
      };
    },
    dataURLtoBlob(dataURL) {
      return new Promise((resolve) => {
        const arr = dataURL.split(",");
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        resolve(new Blob([u8arr], { type: mime }));
      });
    },
    uploadImage(file, imageUrl) {
      const formData = new FormData();
      formData.append("file", file);
      const token = window.localStorage.getItem("token");
      axios
       .post("https://manage.wandao666.top/api/ai/file/upload", formData, {
          headers: {
            token: token
          }
        })
       .then((res) => {
          const filePath = `https://manage.wandao666.top/ai/${res.data.data.filePath.substring(
            res.data.data.filePath.indexOf("/upload")
          )}`;
          this.selectedImages.push({
            file: file,
            url: imageUrl,
            filePath: filePath
          });
        })
       .catch((error) => {
          console.error("图片上传失败:", error);
        });
    },
    removeImage(index) {
      this.selectedImages.splice(index, 1);
    },
    sendMessage() {
      if (!this.inputMessage.trim() && this.selectedImages.length === 0) return;
      this.isSending = true; // 禁用发送按钮
      const imagePaths = this.selectedImages.map((image) => image.filePath);

      const userMessage = {
        role: "user",
        content: this.inputMessage,
        timestamp: new Date(),
        anserFiles: imagePaths
      };
      this.activeChat.messages.push(userMessage);

      let aiMessage = {
        role: "assistant",
        content: "正在思考中...",
        timestamp: new Date(),
        anserFiles: []
      };
      this.activeChat.messages.push(aiMessage);

      this.loading = true;

      const chatId = this.activeChat.id;
      const msg = this.inputMessage;
      const imageUrls = imagePaths.join(",");
      const filesParam =
        imagePaths.length > 0
         ? `&files=${encodeURIComponent(imagePaths.join(","))}`
          : "";
      const url = `https://manage.wandao666.top/api/ai/chat?chatId=${chatId}&msg=${encodeURIComponent(
        msg
      )}&imageUrls=${encodeURIComponent(imageUrls)}${filesParam}`;

      const eventSource = new EventSource(url, {
        timeout: 300000
      });

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === "answer") {
            if (aiMessage.content === "正在思考中...") {
                aiMessage.content = "";
                aiMessage.timestamp = new Date(); // 更新为回答时间
            }
            aiMessage.content += data.content;
            if (data.anserFiles && data.anserFiles.length > 0) {
              aiMessage.anserFiles = aiMessage.anserFiles.concat(
                data.anserFiles
              );
            }
            this.$forceUpdate();
            this.scrollToBottom();
            this.loading = false;
			this.isSending = false; // 启用发送按钮
          }
        } catch (error) {
          console.error("Error handling SSE data:", error);
        }
      };

      eventSource.onerror = (error) => {
        console.error("SSE connection error:", error);
        eventSource.close();
        this.loading = false;
	     this.isSending = false; // 启用发送按钮
      };

      this.inputMessage = "";
      this.selectedImages = [];
      this.scrollToBottom();
    },
    formatTime(date) {
      const pad = (n) => n.toString().padStart(2, "0");
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}`;
    },
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector(".message-area");
        container.scrollTop = container.scrollHeight;
      });
    },
    renderMarkdown(text) {
      const html = md.render(text);
      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");
      const links = doc.querySelectorAll("a");
      links.forEach((link) => {
        link.setAttribute("target", "_blank");
        link.style.pointerEvents = "auto";
      });
      return doc.body.innerHTML;
    },
    showPreviewImage(imageUrl) {
      this.previewImage = imageUrl;
    },
  }
};
</script>

<style scoped>
/* 原有样式保持不变 */
.assistant-container {
  display: flex;
  height: 100%;
  background: #f0f2f5;
}

.sidebar {
  width: 260px;
  height: 100%;
  background: #ffffff;
  box-shadow: 2px 0 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 20px;
  overflow: hidden;
}

.message {
  margin: 8px 0;
  max-width: 60%;
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-message {
  margin-left: auto;
  margin-right: 20px;
  background: #76b0e2;
  color: white;
  text-align: left;
}

.ai-message {
  text-align: left;
  margin-right: auto;
  margin-left: 20px;
  background: #ffffff;
}

.message-content {
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: break-word;
}

.message-time {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  text-align: right;
}

.user-message .message-time {
  color: rgba(255, 255, 255, 0.8);
}

.input-area {
  display: flex;
  padding: 20px 0 0;
  gap: 12px;
  flex-wrap: wrap;
}

.message-input {
  flex: 1;
  padding: 12px;
  border: 1px solid #e8e8e8;
  border-radius: 6px;
  resize: vertical;
  min-height: 44px;
  font-family: inherit;
  white-space: pre-wrap;
  word-break: break-word;
  line-height: 1.5;
}

.button-container {
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.selected-images-container {
  display: flex;
  flex-wrap: wrap;
  margin-bottom: 12px;
}

.selected-images {
  display: flex;
  flex-wrap: wrap;
}

.sidebar-header {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
}

.new-chat {
  width: 100%;
  padding: 12px;
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.new-chat:hover {
  background: #40a9ff;
}

.chat-list {
  flex: 1;
  overflow-y: auto;
  padding: 10px;
}

.chat-item {
  padding: 12px;
  margin: 8px 0;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.chat-item:hover {
  background: #f5f5f5;
}

.chat-item.active {
  background: #e6f7ff;
  color: #1890ff;
}

.send-button {
  padding: 0 24px;
  height: 44px;
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.send-button:hover {
  background: #40a9ff;
}

.message-area {
  flex: 1;
  padding: 20px;
  overflow-y: auto;
  background: #fafafa;
  border-radius: 8px;
  margin: 0;
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1890ff;
  margin: 0 5px;
  animation: loading 1.5s infinite ease-in-out;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.select-image-button {
  padding: 0 24px;
  height: 44px;
  background: #1890ff;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: background 0.3s;
}

.select-image-button:hover {
  background: #40a9ff;
}

.selected-image {
  position: relative;
  margin-right: 12px;
  margin-bottom: 12px;
}

.selected-image img {
  max-width: 100px;
  max-height: 100px;
  border-radius: 6px;
}

.remove-image-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-image-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

/* 新增图片预览模态框样式 */
.image-preview-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.preview-image {
  max-width: 90%;
  max-height: 90%;
  border-radius: 8px;
  object-fit: contain;
}
</style>