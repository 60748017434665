class VoiceAnnouncements {
    constructor() {
        // 检测浏览器是否支持 Web Speech API 
        if ('speechSynthesis' in window && 'SpeechSynthesisUtterance' in window) {
            this.synth = window.speechSynthesis; // 启用文本
            this.msg = new SpeechSynthesisUtterance();
            this.language = 'zh-CN';  // 使用的语言:中文
            this.volume = 1; // 音量
            this.speed = 1;  // 语速
            this.pitch = 1; // 音高
        } else {
            // 若不支持，将相关属性置为 null
            this.synth = null;
            this.msg = null;
            console.log('当前浏览器不支持语音合成功能。');
        }
    }

    // 开始语音提示
    startVoiceFunction(content) {
        if (this.synth && this.msg) {
            this.msg.text = content;
            this.msg.language = this.language;
            this.msg.volume = this.volume;
            this.msg.rate = this.speed; // 将 `speed` 调整为 `rate`
            this.msg.pitch = this.pitch;
            this.synth.speak(this.msg);
        } else {
            console.log('无法启动语音功能，当前浏览器不支持。');
        }
    }

    // 停止语音提示
    stopVoiceFunction() {
        if (this.synth) {
            this.synth.cancel();
        } else {
            console.log('无法停止语音功能，当前浏览器不支持。');
        }
    }
}

// 传出实例，保证整个系统只存在单例的Voice
const VoiceAnnouncementsInstance = new VoiceAnnouncements();

export default VoiceAnnouncementsInstance;