<template>
  <div class="assistant-container">
    <div class="main-content">
      <!-- 消息区域 -->
      <div class="message-area">
        <div
          v-for="(message, index) in activeChat.messages"
          :key="index"
          class="message"
          :class="{
            'user-message': message.role === 'user',
            'ai-message': message.role === 'assistant',
          }"
        >
          <div class="message-content">
            <div
              v-if="message.role === 'assistant'"
              v-html="renderMarkdown(message.content)"
            ></div>
            <div v-else>{{ message.content }}</div>
            <div v-if="message.anserFiles && message.anserFiles.length > 0">
              <img
                v-for="(fileUrl, fileIndex) in message.anserFiles"
                :key="fileIndex"
                :src="fileUrl"
                alt="Uploaded Image"
                style="max-width: 200px; max-height: 200px; margin-top: 10px"
                @click="openPhotoSwipe(fileIndex, message.anserFiles)"
              />
            </div>
          </div>
          <div class="message-time">
            {{ formatTime(message.timestamp) }}
          </div>
        </div>
        <div v-if="loading" class="loading-animation">
          <div class="loading-dot"></div>
          <div class="loading-dot"></div>
          <div class="loading-dot"></div>
        </div>
      </div>

      <!-- 视频模态框 -->
      <VideoModal ref="videoModal" />

      <!-- 已选图片区域 -->
      <div class="selected-images-container">
        <div v-if="selectedImages.length > 0" class="selected-images">
          <div
            v-for="(image, index) in selectedImages"
            :key="index"
            class="selected-image"
            @click="
              openPhotoSwipe(
                index,
                selectedImages.map((img) => img.url)
              )
            "
          >
            <img :src="image.url" alt="Selected Image" />
            <button
              @click.stop="removeImage(index)"
              class="remove-image-button"
            >
              删除
            </button>
          </div>
        </div>
      </div>

      <!-- 输入区域 -->
      <div class="input-area">
        <input
          type="file"
          accept="image/*"
          @change="handleImageSelect"
          style="display: none"
          ref="imageInput"
          multiple
        />
        <input
          type="file"
          accept="image/*"
          @change="handleImageSelect"
          style="display: none"
          ref="cameraInput"
          capture="environment"
          multiple
        />
        <textarea
          v-model="inputMessage"
          @keydown.enter.exact.prevent="sendMessage"
          @keydown.shift.enter.prevent
          placeholder="输入你的消息(聊天、画画、做视频、分析图片内容)..."
          class="message-input"
          rows="5"
        ></textarea>
        <div class="button-container">
          <!-- <button
            @click="takePhoto"
            class="select-image-button"
            :class="{ active: isTakingPhoto }"
          >
            拍照
          </button> -->
          <button
            @click="chooseLocalImage"
            class="select-image-button"
            :class="{ active: isChoosingImage }"
          >
            图库
          </button>
          <button
            @click="sendMessage"
            :disabled="isSending"
            class="send-button"
          >
            发送
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VideoModal from './VideoModal.vue'; // 引入视频模态框组件
import PhotoSwipeLightbox from 'photoswipe/lightbox';
import 'photoswipe/style.css'; // 引入 PhotoSwipe 样式
import axios from 'axios';
import MarkdownIt from 'markdown-it';

const md = new MarkdownIt();

export default {
  components: {
    VideoModal,
  },
  data() {
    return {
      activeIndex: 0,
      inputMessage: "",
      chats: [
        {
          id: Date.now(),
          title: "对话 1",
          messages: [
            {
              role: "assistant",
              content: "你好！我是你的小助理，有什么可以帮您的？",
              timestamp: new Date(),
            },
          ],
        },
      ],
      loading: false,
      selectedImages: [],
      isSending: false,
      isTakingPhoto: false,
      isChoosingImage: false,
    };
  },
  computed: {
    activeChat() {
      return this.chats[this.activeIndex];
    },
  }, 
  mounted(){
    this.bindImageLinkEvents();
    this.bindVideoLinkEvents();
  },
  updated() {
    this.bindImageLinkEvents();
    this.bindVideoLinkEvents();
  },
  methods: {
    // 绑定点击事件
    bindImageLinkEvents() {
      const links = this.$el.querySelectorAll('.image-link');
      links.forEach((link) => {
        if (!link.dataset.listenerAttached) {
          link.addEventListener('click', (event) => {
            event.preventDefault(); // 阻止默认链接行为
            const imageUrl = link.getAttribute('href');
            this.openPhotoSwipe(0, [imageUrl]);
          });
          link.dataset.listenerAttached = true; // 标记已绑定事件
        }
      });
    },
    // 绑定点击事件
    bindVideoLinkEvents() {
      const links = this.$el.querySelectorAll('.video-link');
      links.forEach((link) => {
        if (!link.dataset.listenerAttached) {
          link.addEventListener('click', (event) => {
            event.preventDefault(); // 阻止默认链接行为
            const videoUrl = link.getAttribute('href');
            this.$refs.videoModal.openModal(videoUrl); // 打开视频模态框
          });
          link.dataset.listenerAttached = true; // 标记已绑定事件
        }
      });
    },
    // 打开图片预览
    openPhotoSwipe(index, images) {
      const loadImageSizes = images.map((url) => {
        return new Promise((resolve) => {
          const img = new Image();
          img.src = url;
          img.onload = () => {
            resolve({
              src: url,
              width: img.naturalWidth,
              height: img.naturalHeight,
            });
          };
          img.onerror = () => {
            resolve({
              src: url,
              width: 800,
              height: 800,
            });
          };
        });
      });

      Promise.all(loadImageSizes).then((dataSource) => {
        const lightbox = new PhotoSwipeLightbox({
          dataSource: dataSource,
          pswpModule: () => import('photoswipe'),
        });

        lightbox.on('beforeOpen', () => {
          lightbox.pswp.options.index = index;
        });

        lightbox.init();
        lightbox.loadAndOpen(index);
      });
    },

    // 选择本地图片
    chooseLocalImage() {
      this.isChoosingImage = true;
      this.$refs.imageInput.click();
      setTimeout(() => {
        this.isChoosingImage = false;
      }, 200);
    },

    // 处理图片选择
    handleImageSelect(event) {
      const files = event.target.files;
      if (files.length > 0) {
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const reader = new FileReader();
          reader.onload = (e) => {
            const image = new Image();
            image.src = e.target.result;
            image.onload = () => {
              this.compressImage(image, file).then((compressedImage) => {
                this.uploadImage(compressedImage.file, compressedImage.dataURL);
              });
            };
          };
          reader.readAsDataURL(file);
        }
      }
    },

    // 压缩图片
    async compressImage(image, file) {
      const maxSize = 1 * 1024 * 1024; // 1MB
      const maxWidth = 1024;
      let quality = 0.9;

      let canvas = document.createElement('canvas');
      let ctx = canvas.getContext('2d');

      let width = image.width;
      let height = image.height;

      if (width > maxWidth) {
        height = height * (maxWidth / width);
        width = maxWidth;
      }

      canvas.width = width;
      canvas.height = height;
      ctx.drawImage(image, 0, 0, width, height);

      let dataURL = canvas.toDataURL(file.type, quality);
      let blob = await this.dataURLtoBlob(dataURL);

      while (blob.size > maxSize && quality > 0.1) {
        quality -= 0.1;
        dataURL = canvas.toDataURL(file.type, quality);
        blob = await this.dataURLtoBlob(dataURL);
      }

      const compressedFile = new File([blob], file.name, { type: file.type });
      return {
        file: compressedFile,
        dataURL: dataURL,
      };
    },

    // DataURL 转 Blob
    dataURLtoBlob(dataURL) {
      return new Promise((resolve) => {
        const arr = dataURL.split(',');
        const mime = arr[0].match(/:(.*?);/)[1];
        const bstr = atob(arr[1]);
        let n = bstr.length;
        const u8arr = new Uint8Array(n);
        while (n--) {
          u8arr[n] = bstr.charCodeAt(n);
        }
        resolve(new Blob([u8arr], { type: mime }));
      });
    },

    // 上传图片
    uploadImage(file, imageUrl) {
      this.loading = true;  
      const formData = new FormData();
      formData.append('file', file);
      const token = window.localStorage.getItem('token');
      axios
        .post('https://manage.wandao666.top/api/ai/file/upload', formData, {
          headers: {
            token: token,
          },
        })
        .then((res) => {  
          const filePath = `https://manage.wandao666.top/ai/${res.data.data.filePath.substring(
            res.data.data.filePath.indexOf('/upload')
          )}`;
          this.selectedImages.push({
            file: file,
            url: imageUrl,
            filePath: filePath,
          });
          this.loading = false;
        })
        .catch((error) => {
          this.loading = false;
          console.error('图片上传失败:', error);
        });
    },

    // 删除图片
    removeImage(index) {
      this.selectedImages.splice(index, 1);
    },

    // 发送消息
    sendMessage() {
      if (!this.inputMessage.trim() && this.selectedImages.length === 0) return;
      this.isSending = true;

      const imagePaths = this.selectedImages.map((image) => image.filePath);

      const userMessage = {
        role: 'user',
        content: this.inputMessage,
        timestamp: new Date(),
        anserFiles: imagePaths,
      };
      this.activeChat.messages.push(userMessage);

      let aiMessage = {
        role: 'assistant',
        content: '正在思考中...',
        timestamp: new Date(),
        anserFiles: [],
      };
      this.activeChat.messages.push(aiMessage);

      this.loading = true;

      const chatId = this.activeChat.id;
      const msg = this.inputMessage;
      const imageUrls = imagePaths.join(',');
      const filesParam =
        imagePaths.length > 0
          ? `&files=${encodeURIComponent(imagePaths.join(','))}`
          : '';
      const url = `https://manage.wandao666.top/api/ai/chat?chatId=${chatId}&msg=${encodeURIComponent(
        msg
      )}&imageUrls=${encodeURIComponent(imageUrls)}${filesParam}`;

      const eventSource = new EventSource(url, {
        timeout: 300000,
      });

      eventSource.onmessage = (event) => {
        try {
          const data = JSON.parse(event.data);
          if (data.type === 'answer') {
            if (aiMessage.content === '正在思考中...') {
              aiMessage.content = '';
              aiMessage.timestamp = new Date();
            }
            aiMessage.content += data.content;
            if (data.anserFiles && data.anserFiles.length > 0) {
              aiMessage.anserFiles = aiMessage.anserFiles.concat(
                data.anserFiles
              );
            }
            this.$forceUpdate();
            this.scrollToBottom();
            this.loading = false;
            this.isSending = false;
          }
        } catch (error) {
          console.error('Error handling SSE data:', error);
        }
      };

      eventSource.onerror = (error) => {
        console.error('SSE connection error:', error);
        eventSource.close();
        this.loading = false;
        this.isSending = false;
      };

      this.inputMessage = '';
      this.selectedImages = [];
      this.scrollToBottom();
    },

    // 格式化时间
    formatTime(date) {
      const pad = (n) => n.toString().padStart(2, '0');
      return `${date.getFullYear()}-${pad(date.getMonth() + 1)}-${pad(
        date.getDate()
      )} ${pad(date.getHours())}:${pad(date.getMinutes())}:${pad(
        date.getSeconds()
      )}`;
    },

    // 滚动到底部
    scrollToBottom() {
      this.$nextTick(() => {
        const container = this.$el.querySelector('.message-area');
        container.scrollTop = container.scrollHeight;
      });
    },

    renderMarkdown(text) {
       text = text.replace(/\n+/g, '\n').trim();
      let html = md.render(text);
      html = html.replace(
        /<a href="(.*?\.(?:jpg|jpeg|png|gif|bmp|webp))">(.*?)<\/a>/gi,
        '<a href="$1" class="image-link">$2</a>'
      );
      html = html.replace(
        /<a href="(.*?\.(?:mp4|webm|ogg))">(.*?)<\/a>/gi,
        '<a href="$1" class="video-link">$2</a>'
      );
      html = html.replace(
        /<img src="(.*?\.(?:jpg|jpeg|png|gif|bmp|webp))" alt="(.*?)">/g,
        '<a href="$1"  class="image-link" >$2</a>'
      );
      html = html.replace(
        /<img src="(.*?\.(?:mp4|webm|ogg))" alt="(.*?)">/g,
        '<a href="$1" class="video-link">$2</a>'
      );

      const parser = new DOMParser();
      const doc = parser.parseFromString(html, "text/html");

      const links = doc.querySelectorAll("a");
      links.forEach((link) => {
        link.style.pointerEvents = "auto";
        if (link.classList.contains('image-link')) {
          link.classList.add('no-preview-link');
        } else if (link.classList.contains('video-link')) {
          link.classList.add('no-preview-link');
        }
      });

      return doc.body.innerHTML;
    },
  },
};
</script>

<style scoped>
.assistant-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background: #f0f2f5;
}

.main-content {
  flex: 1;
  display: flex;
  flex-direction: column;
  padding: 10px;
  overflow: hidden;
}

.message-area {
  flex: 1;
  padding: 10px;
  overflow-y: auto;
  background: #fafafa;
  border-radius: 8px;
  margin: 0;
}

.message {
  margin: 8px 0;
  max-width: 80%;
  padding: 8px 12px;
  border-radius: 8px;
  background: white;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.user-message {
  margin-left: auto;
  margin-right: 10px;
  background: #eff6ff;
  color: rgb(11, 11, 11);
  text-align: left;
}

.ai-message {
  text-align: left;
  margin-right: auto;
  margin-left: 10px;
  background: #ffffff;
}

.message-content {
  font-size: 14px;
  line-height: 1.4;
  white-space: pre-wrap;
  word-break: break-word;
}

.message-time {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.6);
  margin-top: 4px;
  text-align: right;
}

.user-message .message-time {
  color: rgba(255, 255, 255, 0.8);
}

.input-area {
  display: flex;
  flex-direction: column;
  padding: 10px 0 20px 0;
  gap: 8px;
}

.input-area input,  /* 目标输入框 */
.input-area textarea {
  /* 如果包含文本域 */
  border-radius: 8px; /* 设置圆角半径 */
  padding: 12px; /* 增加内边距避免内容被截断 */
}

/* 可选： hover 状态下的样式增强 */
.input-area input:focus,
.input-area textarea:focus {
  outline: none;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.2); /* 蓝色焦点环 */
}

.button-container {
  display: flex;
  justify-content: space-between;
  gap: 8px;
}

.select-image-button {
  width: 48%;
  padding: 10px;
  background: #5add0d44;
  color: rgba(14, 13, 13, 0.928);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s, transform 0.2s;
}

.select-image-button.active {
  background: #5add0d44;
  transform: scale(0.95);
}

.select-image-button:hover {
  background: #5add0d44;
}

.send-button {
  width: 100%;
  padding: 10px;
  background: #2192fb;
  color: white;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background 0.3s;
}

.send-button:disabled {
  background: #ccc;
  cursor: not-allowed;
}

.send-button:hover {
  background: #40a9ff;
}

.selected-images-container {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
}

.selected-images {
  display: flex;
  flex-wrap: wrap;
}

.selected-image {
  position: relative;
  margin-right: 8px;
  margin-bottom: 8px;
}

.selected-image img {
  max-width: 80px;
  max-height: 80px;
  border-radius: 6px;
}

.remove-image-button {
  position: absolute;
  top: 4px;
  right: 4px;
  background: rgba(0, 0, 0, 0.6);
  color: white;
  border: none;
  border-radius: 50%;
  width: 20px;
  height: 20px;
  font-size: 12px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.remove-image-button:hover {
  background: rgba(0, 0, 0, 0.8);
}

.loading-animation {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100px;
}

.loading-dot {
  width: 10px;
  height: 10px;
  border-radius: 50%;
  background-color: #1890ff;
  margin: 0 5px;
  animation: loading 1.5s infinite ease-in-out;
}

.loading-dot:nth-child(2) {
  animation-delay: 0.3s;
}

.loading-dot:nth-child(3) {
  animation-delay: 0.6s;
}

@keyframes loading {
  0%,
  100% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-10px);
  }
}

.no-preview-link {
  display: inline; /* 确保链接以行内元素显示 */
  text-decoration: underline; /* 添加下划线以表明是链接 */
  color: #1890ff; /* 设置链接颜色 */
  cursor: pointer; /* 鼠标悬停时显示指针样式 */
  /* 防止链接内容被展开预览 */
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%; /* 限制链接宽度 */
}
</style>