var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"assistant-container"},[_c('div',{staticClass:"main-content"},[_c('div',{staticClass:"message-area"},[_vm._l((_vm.activeChat.messages),function(message,index){return _c('div',{key:index,staticClass:"message",class:{
          'user-message': message.role === 'user',
          'ai-message': message.role === 'assistant',
        }},[_c('div',{staticClass:"message-content"},[(message.role === 'assistant')?_c('div',{domProps:{"innerHTML":_vm._s(_vm.renderMarkdown(message.content))}}):_c('div',[_vm._v(_vm._s(message.content))]),(message.anserFiles && message.anserFiles.length > 0)?_c('div',_vm._l((message.anserFiles),function(fileUrl,fileIndex){return _c('img',{key:fileIndex,staticStyle:{"max-width":"200px","max-height":"200px","margin-top":"10px"},attrs:{"src":fileUrl,"alt":"Uploaded Image"},on:{"click":function($event){return _vm.openPhotoSwipe(fileIndex, message.anserFiles)}}})}),0):_vm._e()]),_c('div',{staticClass:"message-time"},[_vm._v(" "+_vm._s(_vm.formatTime(message.timestamp))+" ")])])}),(_vm.loading)?_c('div',{staticClass:"loading-animation"},[_c('div',{staticClass:"loading-dot"}),_c('div',{staticClass:"loading-dot"}),_c('div',{staticClass:"loading-dot"})]):_vm._e()],2),_c('VideoModal',{ref:"videoModal"}),_c('div',{staticClass:"selected-images-container"},[(_vm.selectedImages.length > 0)?_c('div',{staticClass:"selected-images"},_vm._l((_vm.selectedImages),function(image,index){return _c('div',{key:index,staticClass:"selected-image",on:{"click":function($event){_vm.openPhotoSwipe(
              index,
              _vm.selectedImages.map((img) => img.url)
            )}}},[_c('img',{attrs:{"src":image.url,"alt":"Selected Image"}}),_c('button',{staticClass:"remove-image-button",on:{"click":function($event){$event.stopPropagation();return _vm.removeImage(index)}}},[_vm._v(" 删除 ")])])}),0):_vm._e()]),_c('div',{staticClass:"input-area"},[_c('input',{ref:"imageInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","multiple":""},on:{"change":_vm.handleImageSelect}}),_c('input',{ref:"cameraInput",staticStyle:{"display":"none"},attrs:{"type":"file","accept":"image/*","capture":"environment","multiple":""},on:{"change":_vm.handleImageSelect}}),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.inputMessage),expression:"inputMessage"}],staticClass:"message-input",attrs:{"placeholder":"输入你的消息(聊天、画画、做视频、分析图片内容)...","rows":"5"},domProps:{"value":(_vm.inputMessage)},on:{"keydown":[function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if($event.ctrlKey||$event.shiftKey||$event.altKey||$event.metaKey)return null;$event.preventDefault();return _vm.sendMessage.apply(null, arguments)},function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter"))return null;if(!$event.shiftKey)return null;$event.preventDefault();}],"input":function($event){if($event.target.composing)return;_vm.inputMessage=$event.target.value}}}),_c('div',{staticClass:"button-container"},[_c('button',{staticClass:"select-image-button",class:{ active: _vm.isChoosingImage },on:{"click":_vm.chooseLocalImage}},[_vm._v(" 图库 ")]),_c('button',{staticClass:"send-button",attrs:{"disabled":_vm.isSending},on:{"click":_vm.sendMessage}},[_vm._v(" 发送 ")])])])],1)])
}
var staticRenderFns = []

export { render, staticRenderFns }