<template>
  <div class="login">
    <el-form ref="form" class="login-container" :rules="rules" :model="form">
      <h1 class="title">用户登录</h1>
      <el-form-item label="" prop="userName">
        <el-input
          ref="input1"
          type="text"
          v-model="form.userName"
          placeholder="登录账号"
          @keyup.enter.native="handelTab(1, $event)"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item label="" prop="password">
        <el-input
          ref="input2"
          type="password"
          v-model="form.password"
          placeholder="登录密码"
          @keyup.enter.native="doSubmit"
          autocomplete="off"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" style="width: 100%" @click="doSubmit()"
          >提交</el-button
        >
      </el-form-item>
      <el-row style="text-align: center; margin-top: -10px">
        <el-link type="primary">忘记密码</el-link>
        <el-link type="primary" @click="gotoRegister()">用户注册</el-link>
      </el-row>
    </el-form>
  </div>
</template>


<script>

    export default{
         data(){
            return {
                form:{
                    userName:'',
                    password:''
                },
                rules:{
                    userName:[
                        {required: true, message: '请输入用户名'},
                        {min: 3, max: 15, message: '长度大于3 小于15'}
                    ],
                    password:[
                        {required: true, message: '请输入密码'},
                        {min: 3, max: 15, message: '长度大于3 小于15'}
                    ]
              }
            }
        },
        methods:{
            handelTab(i, e) {
                let that=this
                if(that.$refs['input'+i]){
                    that.$nextTick(()=>{
                        e.target.blur()
                        let index=i+1
                        that.$refs['input'+index].focus()
                    })
                }
            },
            doSubmit(){       
                console.log("开始登录...")       
                this.$refs.form.validate((valid) =>{
                   if(valid)
                   {
                       console.log("登录校验通过")       
                       this.$httpapi.login(this.form).then((data) =>{
                        console.log(data)
                            if(data.data.code === 200){                                
                                window.localStorage.setItem('token', data.data.data.token);
                                window.localStorage.setItem('userName', this.form.userName);
                                window.localStorage.setItem('domain', this.$domain);
                                  //跳转到主页面
                                this.$router.push('/aiChat')                            
                            } else{
                                console.log("登录失败"+data.data.msg)
                                this.$message.error(data.data.msg)
                            }
                       }).catch((error) => {
                                console.error("网络断开或服务挂了", error);
                                this.$message.error("网络断开或服务挂了，请稍后再试");
                        });
                   }
               })

            } 
        }
    }
</script>
<style lang="less">
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;

  .login-container {
    border-radius: 10px;
    margin: 0px auto;
    width: 350px;
    padding: 30px 35px 15px 35px;
    background: #fff;
    border: 1px solid #eaeaea;
    text-align: left;
    box-shadow: 0 0 20px 2px rgba(0, 0, 0, 0.1);
    .title {
      margin: 0px auto 40px auto;
      text-align: center;
      color: #505458;
    }
  }
}
</style>