import { render, staticRenderFns } from "./AiChat.vue?vue&type=template&id=21281665&scoped=true"
import script from "./AiChat.vue?vue&type=script&lang=js"
export * from "./AiChat.vue?vue&type=script&lang=js"
import style0 from "./AiChat.vue?vue&type=style&index=0&id=21281665&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21281665",
  null
  
)

export default component.exports