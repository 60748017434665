<template>
  <div v-if="isVisible" class="video-modal" @click="handleOutsideClick">
    <div class="video-modal-content">
      <div class="video-wrapper" ref="videoWrapper">
        <video
          ref="videoPlayer"
          controls
          :src="videoUrl"
          :autoplay="isAutoplay"
          @loadeddata="onVideoLoaded"
          @pause="onVideoPause"
          @fullscreenchange="handleVideoFullscreenChange"
          @click.stop="handleVideoClick"
        ></video>
        <button
          class="close-button"
          type="button"
          @click="closeModal"
          aria-label="关闭视频"
          tabindex="0"
        >
          &times;
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'VideoModal',
  data() {
    return {
      isVisible: false,
      videoUrl: '',
      isAutoplay: false,
      isFullscreen: false,
      isVideoPlaying: false,
      fullscreenRequested: false,
    };
  },
  computed: {
    shouldAutoFullscreen() {
      return this.isVisible && !this.fullscreenRequested;
    },
  },
  watch: {
    shouldAutoFullscreen: {
      immediate: true,
      handler() {
        if (this.shouldAutoFullscreen) {
          this.requestFullscreenAsync(this.$refs.videoPlayer).catch(console.error);
        }
      },
    },
  },
  methods: {
    /**
     * 打开视频模态框
     * @param {string} url - 视频地址
     */
    openModal(url) {
      this.videoUrl = url;
      this.isVisible = true;
      this.isAutoplay = true;
      this.fullscreenRequested = false;
      this.isVideoPlaying = false;

      this.$nextTick(() => {
        this.$refs.videoPlayer.load();
        this.$refs.videoPlayer.style.display = 'block';
      });
    },

    /**
     * 关闭视频模态框
     */
    closeModal() {
      if (!this.isVisible) return;

      try {
        this.exitFullscreen();
        this.pauseVideo();
        this.resetVideoState();
      } catch (error) {
        console.error('关闭视频时发生错误:', error);
      } finally {
        this.isVisible = false;
        this.videoUrl = '';
        this.isAutoplay = false;
        this.fullscreenRequested = false;
        this.isFullscreen = false;
        this.$refs.videoPlayer.style.display = 'none';
      }
    },

    /**
     * 请求进入全屏（异步）
     * @param {HTMLVideoElement} element - 视频元素
     */
    requestFullscreen(element) {
      if (!element) return Promise.reject(new Error('视频元素不存在'));

      const requestMethods = [
        element.requestFullscreen,
        element.webkitRequestFullscreen,
        element.msRequestFullscreen,
        element.mozRequestFullScreen
      ];

      for (const method of requestMethods) {
        if (typeof method === 'function') {
          return method.call(element).catch(error => {
            console.warn('全屏请求失败:', error);
            return Promise.reject(error);
          });
        }
      }

      return Promise.reject(new Error('全屏请求失败'));
    },

    /**
     * 异步请求全屏
     * @param {HTMLVideoElement} element - 视频元素
     */
    requestFullscreenAsync(element) {
      return new Promise((resolve, reject) => {
        this.requestFullscreen(element)
          .then(() => {
            this.fullscreenRequested = true;
            resolve();
          })
          .catch(reject);
      });
    },

    /**
     * 退出全屏
     */
    exitFullscreen() {
      const exitMethods = [
        document.exitFullscreen,
        document.webkitExitFullscreen,
        document.msExitFullscreen,
        document.mozCancelFullScreen
      ];

      for (const method of exitMethods) {
        if (typeof method === 'function') {
          method.call(document).catch(error => {
            console.warn('全屏退出失败:', error);
          });
          break;
        }
      }

      this.fullscreenRequested = false;
    },

    /**
     * 处理视频点击
     */
    handleVideoClick(event) {
      if (event.target === this.$refs.videoPlayer) {
        this.togglePlayPause();
      }
    },

    /**
     * 切换播放/暂停
     */
    togglePlayPause() {
      if (this.$refs.videoPlayer.paused) {
        this.$refs.videoPlayer.play().catch(error => {
          console.warn('自动播放被阻止:', error);
        });
      } else {
        this.$refs.videoPlayer.pause();
      }
      this.isVideoPlaying = !this.$refs.videoPlayer.paused;
    },

    /**
     * 重置视频状态
     */
    resetVideoState() {
      this.$refs.videoPlayer.pause();
      this.$refs.videoPlayer.currentTime = 0;
      this.$refs.videoPlayer.volume = 1.0;
    },

    /**
     * 视频加载完成处理
     */
    onVideoLoaded() {
      this.isVideoPlaying = true;
      this.$refs.videoPlayer.play().catch(error => {
        console.warn('自动播放被阻止:', error);
      });
    },

    /**
     * 视频暂停处理
     */
    onVideoPause() {
      this.isVideoPlaying = false;
    },

    /**
     * 处理视频全屏变化
     */
    handleVideoFullscreenChange(e) {
      this.isFullscreen = document.fullscreenElement === this.$refs.videoPlayer;
    },

    /**
     * 处理模态框外部点击
     * @param {MouseEvent} event 
     */
    handleOutsideClick(event) {
      const modalContent = event.currentTarget.querySelector('.video-modal-content');
      if (!modalContent || !modalContent.contains(event.target)) {
        this.closeModal();
      }
    },
  },
  mounted() {
    // 事件监听器
    document.addEventListener('fullscreenchange', this.handleGlobalFullscreenChange);
    document.addEventListener('webkitfullscreenchange', this.handleGlobalFullscreenChange);
    document.addEventListener('mozfullscreenchange', this.handleGlobalFullscreenChange);
    document.addEventListener('MSFullscreenChange', this.handleGlobalFullscreenChange);

    document.addEventListener('keydown', (event) => {
      if (event.key === 'Escape' && this.isVisible) {
        this.closeModal();
      }
    });
  },
  beforeDestroy() {
    // 清理事件监听器
    document.removeEventListener('fullscreenchange', this.handleGlobalFullscreenChange);
    document.removeEventListener('webkitfullscreenchange', this.handleGlobalFullscreenChange);
    document.removeEventListener('mozfullscreenchange', this.handleGlobalFullscreenChange);
    document.removeEventListener('MSFullscreenChange', this.handleGlobalFullscreenChange);

    document.removeEventListener('keydown', this.handleEscapeKey);
  },
};
</script>

<style scoped>
.video-modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.95);
  overflow: hidden;
  cursor: pointer;
  z-index: 1000;

  /* 点击遮罩层关闭 */
  &::after {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
  }
}

.video-modal-content {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.video-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
  max-width: 1200px;
  max-height: 700px;
  overflow: hidden;
}

video {
  width: 100%;
  height: 100%;
  object-fit: cover;
  cursor: pointer;
  transition: opacity 0.3s ease;
}

.close-button {
  position: absolute;
  top: 24px;
  right: 24px;
  background: #ff4d4f;
  color: white;
  border: none;
  padding: 12px 24px;
  border-radius: 5px;
  cursor: pointer;
  z-index: 1001;
  transition: all 0.3s ease;

  /* 悬停和聚焦状态 */
  &:hover {
    background: #ff7875;
    transform: scale(1.05);
  }
  &:focus {
    outline: none;
    box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.3);
  }

  /* 全屏模式适配 */
  @media (fullscreen) {
    top: 12px;
    right: 12px;
    padding: 8px 16px;
  }
}

/* 响应式设计 */
@media (max-width: 768px) {
  .close-button {
    padding: 8px 16px;
  }
}
</style>