<template>
  <el-menu
    default-active="1-4-1"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    :collapse="isCollapse"
    background-color="#545c64"
    text-color="#fff"
    active-text-color="#ffd04b"
  >
    <div class="menuHeader">
      <h3>{{ projectName }}</h3>
    </div>
    <div class="menu">
      <div class="menu_content" v-for="item in menus">
        <el-submenu
          v-if="item.children && item.children.length > 0"
          :key="item.label"
          :index="item.label"
        >
          <template slot="title">
            <i :class="`el-icon-${item.icon}`"></i>
            <span slot="title">{{ item.label }}</span>
          </template>
          <el-menu-item-group
            v-for="subItem in item.children"
            :key="subItem.name"
            :index="subItem.name"
          >
            <el-menu-item @click="clickMenu(subItem)" index="1-1">{{
              subItem.label
            }}</el-menu-item>
          </el-menu-item-group>
        </el-submenu>

        <el-menu-item v-else @click="clickMenu(item)">
          <i :class="`el-icon-${item.icon}`"></i>
          <span slot="title">{{ item.label }}</span>
        </el-menu-item>
      </div>
    </div>
    <div class="footer">
      <el-button
        @click="handleMenu"
        icon="el-icon-menu"
        size="mini"
      ></el-button>
    </div>
  </el-menu>
</template>
<script>
     export default{
        data(){
            return {
               projectName:"通用后台管理",
               isCollapse: false,  //是否收缩
               menus: [
                       {
                          path: '/AiChat',
                          name: 'AiChat',
                          label: 'AI助手',
                          icon: 'setting',
                          url: 'AiChat.vue'
                         },
                        {
                        path: '/home',
                        name: 'home',
                        label: '首页',
                        icon: 's-home',
                        url: 'Home.vue'
                        },
                        {
                        path: '/mall',
                        name: 'mall',
                        label: '商品管理',
                        icon: 'video-play',
                        url: 'Mall.vue'
                        },  {
                        path: '/malllist',
                        name: 'malllist',
                        label: '商品展示',
                        icon: 'video-play',
                        url: 'MallList.vue'
                        },
                        {
                        path: '/user',
                        name: 'user',
                        label: '用户管理',
                        icon: 'user',
                        url: 'User.vue'
                        }, 
                        // {
                        // path: '/logging',
                        // name: 'logging',
                        // label: '日志管理',
                        // icon: 'message-solid',
                        // url: 'Logging.vue'
                        // },    
                        {
                        path: '/systemconfig',
                        name: 'systemconfig',
                        label: '系统设置',
                        icon: 'setting',
                        url: 'SystemConfig.vue'
                        }, 
                         {
                        path: '/monitor',
                        name: 'monitor',
                        label: '实施监控',
                        icon: 'setting',
                        url: 'Mornitor.vue'
                        },  
                    
                        // {
                        // path: '/rolemng',
                        // name: 'rolemng',
                        // label: '角色管理',
                        // icon: 'setting',
                        // url: 'RoleMng.vue'
                        // },                    
                        // {
                        // label: '其他',
                        // icon: 'location',
                        // children: [
                        //    {
                        //       path: '/page1',
                        //       name: 'page1',
                        //       label: '页面1',
                        //       icon: 'setting',
                        //       url: 'PageOne.vue'
                        //    },
                        //    {
                        //       path: '/page2',
                        //       name: 'page2',
                        //       label: '页面2',
                        //       icon: 'setting',
                        //       url: 'PageTwo.vue'
                        //    },
                        //    {
                        //       path: '/page3',
                        //       name: 'page3',
                        //       label: '页面3',
                        //       icon: 'setting',
                        //       url: 'Page3.vue'
                        //    },
                        //    {
                        //       path: '/page5',
                        //       name: 'page5',
                        //       label: '页面5',
                        //       icon: 'setting',
                        //       url: 'Page5.vue'
                        //    }
                        // ]
                        // }
                     ]
            }
        },
        methods:{
            handleOpen(key, keyPath) {
                  console.log(key, keyPath);
            },
            handleClose(key, keyPath) {
                  console.log(key, keyPath);
            },
            handleMenu()
            {
               this.isCollapse = !this.isCollapse;
               if(this.isCollapse)
               {
                  this.projectName = "后台"
               }else{
                  this.projectName = "通用后台管理"
               }
               console.log(this.isCollapse)
            },
            clickMenu(item){
                  //console.log(item)
                  //当页面的路由与跳转的路由不一致才允许跳转，否则会报错
                  if(this.$route.path != item.path 
                        && !(this.$route.path === '/home' && (item.path === '/')))
                  {
                        this.$router.push(item.path)
                  }
                  this.$store.commit('addTag',item)
             }
        }
     }
</script>


<style lang="less" scoped>
/*隐藏文字 正常情况下不需要特意写这段*/
.el-menu--collapse .el-submenu__title span {
  display: none;
}

/*隐藏 > 正常情况下不需要特意写这段 */
.el-menu--collapse .el-submenu__title .el-submenu__icon-arrow {
  display: none;
}
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-height: 100vh;
}
.el-menu {
  height: 100vh; // 这个和上面的min-height: 100vh;保证菜单栏是一个整屏幕
  border-right: none;
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  .menuHeader {
    background-color: rgb(20, 19, 19);
    top: 0px;
    height: 60px;
    padding: 0px;
    h3 {
      color: #ffffff;
      text-align: center;
      line-height: 60px;
      font-size: 16px;
      font-weight: 600;
    }
  }
  .menu {
    top: 60px;
    height: calc(100% - 105px); //减去上面60 和 下面40 + 5
    overflow: auto;
    .menu_content {
      //border-bottom: 1px solid rgb(179, 170, 170);
      text-align: left; //菜单居左对齐
      display: flex;
      justify-content: flex-start;
      flex-direction: column;
    }
  }

  .footer {
    height: 40px;
    //  width:  calc(100% - 10px);
    background-color: rgb(20, 19, 19);
    padding-top: 5px;
    //  position: fixed;
    bottom: 0px;
  }
}
</style>